import Axios from 'axios';
import Settings from '../utilities/configuration';

class HttpClient {
    AddBasicAuthentication(username, password) {
        this.basicAuthenticationHash = btoa(username + ":" + password)
        return this;
    }

    Get(url, param, cancelToken) {
        let headerConfig = {};

        if (cancelToken) {
            headerConfig.cancelToken = cancelToken;
        }

        return Axios.get(Settings.API_URL + url, {
            params: {
                ...param
            },
            ...headerConfig
        })
        .then(response => response.data)
        .catch(error => {
            return {
                success: false,
                hasError: true,
                errorMessage: error
            }
        });
    }

    Post(url, param, cancelToken, isFormData = false) {
        let config = {};
        
        if (isFormData) {
            config.headers = {
                'Content-Type' : 'multipart/form-data'
            }
        }
        else {
            config.headers = {
                'Content-Type': 'application/json'
            }
        }

        if (cancelToken) {
            config.cancelToken = cancelToken;
        }

        if (this.basicAuthenticationHash) {
            config.headers.Authorization = `Basic ${this.basicAuthenticationHash}`;
        }

        if (!url.startsWith('http')) {
            url = Settings.API_URL + url;
        }

        return Axios.post(url, param, config)
        .then(response => response.data)
        .catch(error => {
            return {
                success: false,
                hasError: true,
                errorMessage: error.response && error.response.data ? error.response.data.errorMessage : ''
            }
        });
    }
}

const httpClient = new HttpClient();
export default httpClient;