import httpClient from '../utilities/HttpClient';

const API = {
    getAvailableListings: '/api/guesty/GetAvailableListings',
    getListingAvailability: '/api/guesty/GetListingAvailability',
    createReservation: '/api/guesty/CreateReservation',
    getLocalListings: '/api/guesty/GetLocalListings',
    getListingAvailabilityByDate: '/api/guesty/GetListingAvailabilityByDate',
    getCheckListingAvailability: '/api/guesty/CheckListingAvailability',
    addPaymentToReservation: '/api/guesty/AddPaymentToReservation',
    getListingPrice: '/api/guesty/GetListingPrice',
    refreshReservationStatus: '/api/guesty/RefreshReservationStatus',

    postLogError: '/api/guesty/LogError',

    createReservationOpenApi: '/api/guesty/CreateReservationOpenApi',
    createInquiry: '/api/guesty/createInquiry',
    checkBitcoinPaymentStatus: '/api/guesty/cbps', // Check Bitcoin Payment Status
    
    validateDates: '/api/guesty/ValidateDates',
    createBookingSession: '/api/guesty/CreateBookingSession',
    sessionPoll: '/api/guesty/pollstore',
    deleteBookingSession: '/api/guesty/deleteBookingSession'
}

// const externalAPI = {
//     guestyUrl: 'https://api.guesty.com/api/v2/reservations/{0}/payments',
//     guestyUsername: '5926c6eaeb394ea9096614790b8820c3',
//     guestySecret: '4162c92b9d11141fe49cdc954b82b954'
// }

// const cancelToken = Axios.CancelToken;

class ListingService {
    async getAvailableListings(checkin, checkout, noOfGuests, token) {
        const param = {
            checkin, checkout, noOfGuests
        }

        if (checkin && checkout) {
            return httpClient.Get(API.getAvailableListings, param, token);
        }
        else {
            return httpClient.Get(API.getLocalListings, null, token);
        }
    }
    
    async getListingAvailability(listingId, checkin, checkout, noOfGuests, token) {
        const param = {
            listingId, checkin, checkout, noOfGuests
        }
        
        return httpClient.Get(API.getListingAvailability, param, token);
    }

    async getListingAvailabilityByDate(listingId, checkin, checkout, noOfGuests, token) {
        const param = {
            listingId, checkin, checkout, noOfGuests
        }
        
        return httpClient.Get(API.getListingAvailabilityByDate, param, token);
    }

    async createReservation(data, token) {
        return httpClient.Post(API.createReservation, data, token);
    }

    async getListingPrice(data, token) {
        return httpClient.Get(API.getListingPrice, data, token);
    }

    async addPaymentToReservation(data, token) {
    // async addPaymentToReservation(reservationId, data, token) {
        return httpClient.Post(API.addPaymentToReservation, data, token);
        // return httpClient.AddBasicAuthentication(externalAPI.guestyUsername, externalAPI.guestySecret).Post(externalAPI.guestyUrl.replace('{0}', reservationId), data, token);
    }

    async refreshReservationStatus(data, token) {
        return httpClient.Get(API.refreshReservationStatus, data, token);
    }

    async checkListingAvailability(data, token) {
        return httpClient.Get(API.getCheckListingAvailability, data, token);
    }

    async logError(data, token) {
        return httpClient.Post(API.postLogError, data, token);
    }

    async createReservationOpenApi(data, token) {
        return httpClient.Post(API.createReservationOpenApi, data, token);
    }

    async isBitcoinPaymentEnabled(token) {
        var response = await httpClient.Get(API.checkBitcoinPaymentStatus, {}, token);
        return response.success ? response.data : false;
    }

    async validateDates(data, token) {
        return httpClient.Get(API.validateDates, data, token);
    }

    async createBookingStore(data, token) {
        return httpClient.Post(API.createBookingSession, data, token);
    }

    async pollSession(data, token) {
        return httpClient.Get(API.sessionPoll, data, token);
    }
    async deleteBookingSession(data, token) {
        return httpClient.Get(API.deleteBookingSession, data, token);
    }
    
    async createInquiry(data, token) {
        return httpClient.Post(API.createInquiry, data, token);
    }
}

const listingService = new ListingService();
export default listingService;